import React, { FC } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const Step4_2: FC = () => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Indirizzo di spedizione</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/* Indirizzo */}
      <div className='fv-row mb-10'>
        <label htmlFor='Indirizzo_sped' className='form-label required'>
          Indirizzo
        </label>
        <Field
          type='text'
          id='Indirizzo_sped'
          name='Indirizzo_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Indirizzo_sped' />
          </div>
        
      </div>

      {/* Appartamento_Interno_Scala */}
      <div className='fv-row mb-10'>
        <label htmlFor='Appartamento_Interno_Scala_sped' className='form-label'>
          Appartamento, Interno, Scala
        </label>
        <Field
          type='text'
          id='Appartamento_Interno_Scala_sped'
          name='Appartamento_Interno_Scala_sped'
          className='form-control form-control-lg form-control-solid'
        />

        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Appartamento_Interno_Scala_sped' />
          </div>
        
      </div>

      {/* Citta */}
      <div className='fv-row mb-10'>
        <label htmlFor='Citta_sped' className='form-label required'>
          Citta
        </label>
        <Field
          type='text'
          id='Citta_sped'
          name='Citta_sped'
          className='form-control form-control-lg form-control-solid'
        />

        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Citta_sped' />
          </div>
        
      </div>

      {/* Provincia */}
      <div className='fv-row mb-10'>
        <label htmlFor='Provincia_sped' className='form-label required'>
          Provincia
        </label>
        <Field
          type='text'
          id='Provincia_sped'
          name='Provincia_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Provincia_sped' />
          </div>
        
      </div>

      {/* CAP */}
      <div className='fv-row mb-10'>
        <label htmlFor='CAP_sped' className='form-label required'>
          CAP
        </label>
        <Field
          type='text'
          id='CAP_sped'
          name='CAP_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='CAP_sped' />
          </div>      
      </div> 
    </div>
);
};

export { Step4_2 };
