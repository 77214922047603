import * as Yup from 'yup'
import {validationConfig4} from '../../../../validationConfig';

export interface ICreateIntermediary {
    Nome: string
}

export const createIntermediarySchemas = Yup.object().shape({
  Nome: validationConfig4.Nome.required
    ? Yup.string().required('Nome obbligatorio').label('Nome')
    : Yup.string().label('Nome'),
});

export const inits: ICreateIntermediary = {
    Nome: '',
}
