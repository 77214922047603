/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import Select from 'react-select'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {listCategory, transferGoggle} from '../../../../api/gogglesApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

export interface ICategory {
  ID_Categoria: number
  Nome: string
  Descrizione: string
}

interface IOption {
  value: number
  label: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TransferGoggle = ({ show, handleClose, recordId}: Props) => {
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<IOption | null>(null);
  

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    if (show) {
      fetchCategoryList();
    }
  }, [show]);

  const fetchCategoryList = async () => {
    try {
      const response = await listCategory();
      setCategoryList(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTransferGoggle = async () => {
    try {
      if (selectedCategoryId !== null) {
        const response = await transferGoggle(recordId, selectedCategoryId.value);
        handleClose();
        if (response.status === 200) {
          setModalMessage(response.data);
        }
        setShowModal(true);
      } else {
          alert("Per favore, seleziona una categoria.");
      }
    } catch (error: any) {
      console.error('Errore nella chiamata API:', error);
      if (error.response) {
        // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
        if (error.response.status === 400) {
          setModalMessage(error.response.data);
        } else {
          setModalMessage(error.response.data);
        }
      } else if (error.request) {
        // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
        setModalMessage(error.response.data);
      } else {
        // Qualcosa è andato storto nella creazione della richiesta
        setModalMessage(error.response.data);
      }
      setShowModal(true);
    }
  };

  const closeConfirmModal = () => setShowModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_assign_goggles"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Cambio categoria</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <Select
                className='form-select-solid form-select-lg fw-bold'
                value={selectedCategoryId}
                placeholder="Scegli categoria"
                options={categoryList.map(categoria => ({ value: categoria.ID_Categoria, label: categoria.Nome }))}
                onChange={(selectedOption: IOption | null) => {
                  if (selectedOption) {
                    setSelectedCategoryId(selectedOption);      
                  } else {
                    setSelectedCategoryId(null);
                  }
                }}
                isClearable={false}
        />
        <br></br>
        <button className='btn btn-lg btn-light-primary me-3' onClick={handleTransferGoggle}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Cambio categoria</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>{modalMessage}</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { TransferGoggle };