/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {goggleDetails} from '../../../../api/gogglesApi'
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewDetailsGoggles = ({ show, handleClose, recordId }: Props) => {
  const [recordDetails, setRecordDetails] = useState<any>(null);

  useEffect(() => {
    if (show) {
      fetchRecordDetails(recordId);
    }
  }, [show, recordId]);

  const fetchRecordDetails = async (id: number) => {
    try {
      const response = await goggleDetails(id);
      
      setRecordDetails(response[0]);
    } catch (error) {
      console.error(error);
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_view_details"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Dettagli visore</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        {recordDetails ? (
        <div className="row"> {/* Contenitore esterno della griglia */}

          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 1: Credenziali */}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>
                    {recordDetails.Email !== null ? recordDetails.Email : "N/D"}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Password</label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 me-2'>
                    {recordDetails.Password !== null ? recordDetails.Password : "N/D"}
                  </span>
                </div>
              </div>

            </div>
            
            <br></br><br></br><br></br><br></br><br></br>

          </div>

        </div>
        ) : (
        <div className="row"> {/* Contenitore esterno della griglia */}

          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 1: Credenziali */}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>
                    Non inserita
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Password</label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 me-2'>
                  Non inserita
                  </span>
                </div>
              </div>

            </div>
            
            <br></br><br></br><br></br><br></br><br></br>
          </div>

        </div>
        )}
          </div>
        </div>
      </Modal>,
    modalsRoot
  );
};

export { ViewDetailsGoggles };