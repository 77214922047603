/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect}  from 'react'
import { Field, ErrorMessage } from 'formik'
import Select from 'react-select'
import { useFormikContext } from 'formik';
import {listSimulations, listSubscriptionPlans, listAdditionalOptions, listSubscriptionLengths} from '../../../../../api/ordersApi'


const Step3: FC = () => {

  const { setFieldValue } = useFormikContext();
  const [simulationsList, setSimulationsList] = useState<any[]>([]);
  const [subscriptionPlansList, setSubscriptionPlansList] = useState<any[]>([]);
  const [subscriptionLengthsList, setSubscriptionLengthsList] = useState<any[]>([]);
  const [selectedSimulation, setSelectedSimulation] = useState<string[]>([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>('');
  const [subscriptionLength, setSubscriptionLength] = useState<number>();
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [selectedLength, setSelectedLength] = useState<any>(null);
  const [additionalOptions, setAdditionalOptions] = useState<any[]>([]); 
  const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const response = await listSubscriptionPlans();
        const filteredPlans = response.filter(
          (plan:any) => !['Custom_7', 'Custom_4', 'Custom_9', 'Custom_8'].includes(plan.Nome)
        );
        setSubscriptionPlansList(filteredPlans);
      } catch (error) {
        console.error('Errore nel recupero dei piani d\'abbonamento:', error);
      }
    };
    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    const fetchSubscriptionLength = async () => {
      try {
        const response = await listSubscriptionLengths();
        const sortedLengths = response.sort((a:any, b:any) => a.Durata - b.Durata);
        setSubscriptionLengthsList(sortedLengths);
      } catch (error) {
        console.error('Errore nel recupero delle lunghezze d\'abbonamento:', error);
      }
    };
    fetchSubscriptionLength();
  }, []);

  useEffect(() => {
    const fetchSimulazioni = async () => {
      try {
        const response2 = await listSimulations();
        setSimulationsList(response2);
      } catch (error) {
        console.error('Errore nel recupero delle simulazioni:', error);
      }
    };
    fetchSimulazioni();
  }, []);

  useEffect(() => {
    // Aggiorna il valore del piano di abbonamento nel contesto di Formik
    setFieldValue('Piano_abbonamento', subscriptionPlan);
  }, [subscriptionPlan, setFieldValue]);

  const handlePlanChange = (selectedOption: any) => {
    const plan = subscriptionPlansList.find(plan => plan.ID_Piano_abbonamento === selectedOption.value);
    setSelectedPlan(plan);
    setSubscriptionPlan(plan.Nome); // Suppongo che "Nome" sia il nome del piano
    
    // Resetta le simulazioni selezionate quando cambia il piano di abbonamento
    setSelectedSimulation([]);
    setFieldValue('Simulazioni_scelte', []);
  
    // Se il piano selezionato è "Matrix", seleziona tutte le simulazioni
    if (plan.Nome === 'Matrix') {
      const allSimulations = simulationsList
        .filter(simulation => simulation.Tipologia === 'Pronto uso')
        .map(simulation => simulation.Nome);
      setSelectedSimulation(allSimulations);
      setFieldValue('Simulazioni_scelte', allSimulations);
    }
  };

  const handleLengthChange = (selectedOption: any) => {
    const lunghezza = subscriptionLengthsList.find(lunghezza => lunghezza.ID_Durata_abbonamento === selectedOption.value);
    setSelectedLength(lunghezza);
    const durataAsNumber = Number(lunghezza.Durata); // Conversione in numero
    setSubscriptionLength(durataAsNumber); // Imposta il valore come numero
    setFieldValue('Lunghezza_abbonamento', durataAsNumber); // Assicurati che questo sia il campo corretto e usato in Formik
  };

  const handleSimulationChange = (selectedOptions: any) => {
    if (selectedOptions && Array.isArray(selectedOptions)) {
      if (selectedPlan && selectedOptions.length <= selectedPlan.Num_max_simulazioni) {
        const selectedNames = selectedOptions.map((option: any) => option.value);
        setSelectedSimulation(selectedNames);
        setFieldValue('Simulazioni_scelte', selectedNames);
      } else if (!selectedPlan) {
        alert('Per favore, seleziona un piano di abbonamento prima di scegliere le simulazioni.');
      } else {
        alert(`Non puoi selezionare più di ${selectedPlan.Num_max_simulazioni} simulazioni.`);
      }
    } else {
      setSelectedSimulation([]);
      setFieldValue('Simulazioni_scelte', []);
    }
  };

  useEffect(() => {
  const fetchAdditionalOptions = async () => {
    try {
      const response = await listAdditionalOptions();
      setAdditionalOptions(response);
    } catch (error) {
      console.error('Errore nel recupero delle opzioni aggiuntive:', error);
    }
  };
    fetchAdditionalOptions();
  }, []);

  const handleAdditionalOptionsChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setSelectedAdditionalOptions(selectedValues);
    setFieldValue('Opzioni_aggiuntive_scelte', selectedValues);
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Servizi</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/*Inizio piano d'abbonamento*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Piano_abbonamento' className='d-flex align-items-center form-label mb-5'>
          Piano d'abbonamento
        </label>
        
        <div className='row mb-2' data-kt-buttons='true'>
          <Select
            className='form-select-solid form-select-lg fw-bold'
            options={subscriptionPlansList.map((pianoAbbonamento) => ({ value: pianoAbbonamento.ID_Piano_abbonamento, label: pianoAbbonamento.Nome }))}
            isSearchable={false}
            onChange={handlePlanChange}
            placeholder= "Seleziona un piano d'abbonamento"
          />
        </div>

        <div className='text-danger mt-2'>
            <ErrorMessage name='Piano_abbonamento' />
        </div>

        <div className='form-text'>
          
        </div>
      </div>
      {/*Fine piano d'abbonamento*/}

      {/*Inizio lunghezza abbonamento*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Lunghezza_abbonamento' className='form-label required'>
          Lunghezza abbonamento (mesi) (min. 3)
        </label>

        {/*<Field
          type='number'
          id='Lunghezza_abbonamento'
          name='Lunghezza_abbonamento'
          className='form-control form-control-lg form-control-solid'
        />*/}

        <div className='row mb-2' data-kt-buttons='true'>
          <Select
            className='form-select-solid form-select-lg fw-bold'
            options={subscriptionLengthsList.map((lunghezzaAbbonamento) => ({ value: lunghezzaAbbonamento.ID_Durata_abbonamento, label: lunghezzaAbbonamento.Durata }))}
            isSearchable={false}
            onChange={handleLengthChange}
            placeholder= "Seleziona una lunghezza d'abbonamento"
          />
        </div>
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Lunghezza_abbonamento' />
          </div>  
      </div>
      {/*Fine lunghezza abbonamento*/}

      {/*Inizio simulazioni scelte*/}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Simulazioni scelte
        </label>

        <Select
            isMulti
            className='form-select-solid form-select-lg fw-bold'
            value={selectedSimulation.map((nome) => ({ value: nome, label: nome }))}
            placeholder="Scegli simulazioni"
            options={simulationsList.map((simulation) => ({ value: simulation.Nome, label: simulation.Nome }))}
            onChange={handleSimulationChange}
        />

        <div className='text-danger mt-2'>
            <ErrorMessage name='Simulazioni_scelte' />
        </div>

        <div className='form-text'>
          
        </div>
      </div>
      {/*Fine simulazioni scelte*/}

      {/* Inizio opzioni aggiuntive */}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Opzioni aggiuntive
        </label>
        
        <Select
          isMulti
          className='form-select-solid form-select-lg fw-bold'
          value={selectedAdditionalOptions.map((nome) => ({ value: nome, label: nome }))}
          placeholder="Scegli opzioni aggiuntive"
          options={additionalOptions.map((option) => ({ value: option.Nome, label: option.Nome }))}
          onChange={handleAdditionalOptionsChange}
        />

      </div>
      {/* Fine opzioni aggiuntive */}

      {/*Inizio importo effettivo*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Importo_effettivo' className='form-label required'>
          Importo effettivo
        </label>
        <Field
          type='number'
          id='Importo_effettivo'
          name='Importo_effettivo'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Importo_effettivo' />
          </div>  
      </div>
      {/*Fine importo effettivo*/}

      {/*Inizio note aggiuntive*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Note_aggiuntive' className='form-label'>
          Note aggiuntive
        </label>
        <Field
          as='textarea'
          id='Note_aggiuntive'
          name='Note_aggiuntive'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Note_aggiuntive' />
          </div>  
      </div>
      {/*Fine note aggiuntive*/}
    </div>
  )
}

export {Step3}