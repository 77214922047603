import React, { useState, createContext, useContext, ReactNode, useEffect } from 'react';
import axios from '../config/axiosConfig';
import { useCookies } from 'react-cookie';
import {getUserByToken} from '../api/api'
import {useAuth} from '../app/modules/auth/core/Auth'
import {AuthModel} from '../app/modules/auth/core/_models'

interface IUserRoleContext {
  roles: any[];
  setRoles: (roles: string[]) => void;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (nome: string, cognome: string, email: string, password: string) => Promise<void>;
  isAuthenticating: boolean;
}

const UserRoleContext = createContext<IUserRoleContext | null>(null);

interface UserRoleProviderProps {
  children: ReactNode;
}

export const UserRoleProvider: React.FC<UserRoleProviderProps> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['api_token']);
  const [auth, setAuth] = useState<AuthModel | undefined>({
    api_token: cookies.api_token
  });
  const [roles, setRoles] = useState<string[]>([]);
  const {saveAuth, setCurrentUser} = useAuth();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
        if (!cookies.api_token) {
          //console.log("Cookie Api token non definito");
          return;
        }

      try {
        //console.log(cookies.api_token);
        const userRolesResponse = await axios.get('/api/auth/user/roles', { headers: { Authorization: `Bearer ${cookies.api_token}` }});
        setRoles(userRolesResponse.data.roles);
        const user = await getUserByToken(cookies.api_token);
        setCurrentUser(user);
      } catch (error) {
        console.error('Errore durante il controllo dell\'autenticazione', error);
      }
    };

    checkAuth();
  }, [cookies.api_token, setCurrentUser]);

  const login = async (email: string, password: string) => {
    setIsAuthenticating(true);
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      const { data } = response;

      if (data.success) {
        setCookie('api_token', data.token, { secure: false, httpOnly: false});
        saveAuth({ api_token: data.token });

        /*const [userRolesResponse, user] = await Promise.all([
        axios.get('/api/auth/user/roles', { headers: { Authorization: `Bearer ${data.api_token}` }}),
        getUserByToken(data.api_token)]);*/
		
		const userRolesResponse = await axios.get('/api/auth/user/roles', { headers: { Authorization: `Bearer ${data.token}` }});
		const user = await getUserByToken(data.token);

        setRoles(userRolesResponse.data.roles);
        //const user = await getUserByToken(data.api_token);
        setCurrentUser(user);
      } else {
        throw new Error('Credenziali login non valide');
      }
    } catch (error) {
      console.error('Errore durante il login', error);
    } finally {
      setIsAuthenticating(false); 
    }
  };

  const register = async (nome: string, cognome: string, email: string, password: string) => {
    setIsAuthenticating(true); 
    try {
      const response = await axios.post('/api/auth/register', { nome, cognome, email, password });
      const { data } = response;

      console.log(data.success);

      if (data.success) {
        setCookie('api_token', data.token, { secure: false, httpOnly: false});
        const userRolesResponse = await axios.get('/api/auth/user/roles', { headers: { Authorization: `Bearer ${data.token}` }}); //qua l'errore
        setRoles(userRolesResponse.data.roles);
        const user = await getUserByToken(data.token);
        setCurrentUser(user);
      } else {
        throw new Error('Errore durante la registrazione');
      }
    } catch (error) {
      console.error('Errore durante la registrazione', error);
      throw error;
    } finally {
      setIsAuthenticating(false);  
    }
  };

  const logout = async () => {
    // Invia una richiesta al server per invalidare il token
    try {
      await axios.post('/api/auth/logout', { token: cookies.api_token });
    } catch (error) {
      console.error('Errore durante il logout', error);
    }

    // Cancella il cookie dal lato client
    removeCookie('api_token', { path: '/' });
    setRoles([]);
  };

  const value = { roles, setRoles, login, logout, register, isAuthenticating};

  return (
    <UserRoleContext.Provider value={value}>
      {children}
    </UserRoleContext.Provider>
  );
}

export function useRoles() {
  const context = useContext(UserRoleContext);
  if (context === null) {
    throw new Error('useRoles must be used within a UserRoleProvider');
  }
  return context;
}