/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {orderDetails} from '../../../../api/ordersApi'
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewDetailsOrders = ({ show, handleClose, recordId }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>("details");

  const [currentOrderDetails, setCurrentOrderDetails] = useState<any>(null);
  const [previousOrders, setPreviousOrders] = useState<any[]>([]);

  useEffect(() => {
    if (show) {
      fetchRecordDetails(recordId);
      setSelectedTab("details");
    } else {
      setSelectedTab("details");
    }
  }, [show, recordId]);


  const fetchRecordDetails = async (id: number) => {
    try {
      const response = await orderDetails(id);
  
      //Casto le date da formato americano a italiano

      let Spedizione_partenza_stimata_US = dayjs(response.currentOrderDetails[0].Spedizione_partenza_stimata);
      response.currentOrderDetails[0].Spedizione_partenza_stimata = Spedizione_partenza_stimata_US.format('LL');

      let Spedizione_partenza_effettiva_US = dayjs(response.currentOrderDetails[0].Spedizione_partenza_effettiva);
      response.currentOrderDetails[0].Spedizione_partenza_effettiva = Spedizione_partenza_effettiva_US.format('LL');

      let Spedizione_arrivo_stimato_US = dayjs(response.currentOrderDetails[0].Spedizione_arrivo_stimato);
      response.currentOrderDetails[0].Spedizione_arrivo_stimato = Spedizione_arrivo_stimato_US.format('LL');

      let Spedizione_arrivo_effettivo_US = dayjs(response.currentOrderDetails[0].Spedizione_arrivo_effettivo);
      response.currentOrderDetails[0].Spedizione_arrivo_effettivo = Spedizione_arrivo_effettivo_US.format('LL');

      let Data_entrata_ordine_US = dayjs(response.currentOrderDetails[0].Data_entrata_ordine);
      response.currentOrderDetails[0].Data_entrata_ordine = Data_entrata_ordine_US.format('LL');

      let Data_inizio_contratto_US = dayjs(response.currentOrderDetails[0].Data_inizio_contratto);
      response.currentOrderDetails[0].Data_inizio_contratto = Data_inizio_contratto_US.format('LL');

      let Prima_Data_Contratto_US = dayjs(response.currentOrderDetails[0].Prima_Data_Contratto);
      response.currentOrderDetails[0].Prima_Data_Contratto = Prima_Data_Contratto_US.format('LL');

      let createdAt_US = dayjs(response.currentOrderDetails[0].createdAt);
      response.currentOrderDetails[0].createdAt = createdAt_US.format('LL');

      let updatedAt_US = dayjs(response.currentOrderDetails[0].updatedAt);
      response.currentOrderDetails[0].updatedAt = updatedAt_US.format('LL');
      
      /*let Data_fine_contratto_US = Data_inizio_contratto_US.add(response[0].Durata, 'month');
      response[0].Data_fine_contratto = Data_fine_contratto_US.format('LL');*/

      response.previousOrders.forEach((order: any) => {
    
        let Data_inizio_contratto_US = dayjs(order.Data_inizio_contratto);
        order.Data_inizio_contratto = Data_inizio_contratto_US.format('LL');
    
        // Aggiungi altre conversioni di date per gli altri campi, se necessario
      });
      
      setCurrentOrderDetails(response.currentOrderDetails[0]);
      setPreviousOrders(response.previousOrders);

    } catch (error) {
      console.error(error);
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_view_details"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Dettagli Ordine</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1" role="tablist">
          <li className="nav-item mx-lg-1" role="presentation">
            <a className="nav-link py-3 py-lg-6 text-active-primary" data-bs-toggle="tab" data-bs-target="#kt_app_header_menu_pages_pages" 
               role="tab" onClick={() => setSelectedTab("details")}>Informazioni</a>
          </li>
          <li className="nav-item mx-lg-1" role="presentation">
            <a className="nav-link py-3 py-lg-6 text-active-primary" data-bs-toggle="tab" data-bs-target="#kt_app_header_menu_pages_pages" 
               role="tab" onClick={() => setSelectedTab("list")}>Ordini precedenti</a>
          </li>
        </ul>

        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        {currentOrderDetails && (
            selectedTab === "details" ? (
        <div className="row"> {/* Contenitore esterno della griglia */}
          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 1: Dati generali */}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Partita IVA</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{currentOrderDetails.P_IVA === null ? "-" : currentOrderDetails.P_IVA}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Codice fiscale
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{currentOrderDetails.CF === null ? "-" : currentOrderDetails.CF}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Codice SDI</label>

                <div className='col-lg-8'>
                  <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                    {currentOrderDetails.SDI === null ? "-" : currentOrderDetails.SDI}
                  </a>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  PEC
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.PEC === null ? "-" : currentOrderDetails.PEC}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Email === null ? "-" : currentOrderDetails.Email}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Intermediario</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Nome_intermediario === null ? "-" : currentOrderDetails.Nome_intermediario}</span>
                </div>
              </div>
            </div>
          
            <div className='card-body p-9'>
              {/* Blocco 2: Simulazioni scelte */}
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Piano</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Nome_piano === null ? "-" : currentOrderDetails.Nome_piano}</span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Simulazioni scelte</label>

                    <div className='col-lg-8'>
                        {currentOrderDetails.Nome_piano === "Matrix" ? (
                            <span className='fw-bolder fs-6 text-dark'>Tutte</span>
                        ) : (
                            <ul>
                                {currentOrderDetails.Simulazioni.split(',').map((simulazione: string, index: number) => (
                                    <li key={index} className='fw-bolder fs-6 text-dark'>
                                        {simulazione.trim()}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Opzione aggiuntive scelte</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Opzioni_aggiuntive === null ? "-" : currentOrderDetails.Opzioni_aggiuntive}</span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Modello visori</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.ModelliConteggio === null ? "-" : currentOrderDetails.ModelliConteggio}</span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Seriali visori</label>

                    <div className='col-lg-8'>
                      {currentOrderDetails.Numeri_seriali_visori_attivi && currentOrderDetails.Numeri_seriali_visori_attivi !== 'N/A' && (
                          <ul>
                              {currentOrderDetails.Numeri_seriali_visori_attivi.split(',').map((seriale_att: string, index: number) => (
                                  <li key={index} className='fw-bolder fs-6 text-dark'>
                                      {seriale_att.trim()}
                                  </li>
                              ))}
                          </ul>
                      )}
                      {currentOrderDetails.Numeri_seriali_visori_inattivi && currentOrderDetails.Numeri_seriali_visori_inattivi !== 'N/A' && (
                          <ul>
                              {currentOrderDetails.Numeri_seriali_visori_inattivi.split(',').map((seriale_inatt: string, index: number) => (
                                  <li key={index} className='fw-bolder fs-6 text-dark'>
                                      {seriale_inatt.trim()}
                                  </li>
                              ))}
                          </ul> 
                      )}
                    </div>
                </div>
                {/*<div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Sconto</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Percentuale_sconto}%</span>
                    </div>
                </div>*/}
            </div>
          </div>

          <div className="col-md-6"> {/* Contenitore della colonna destra */}
            <div className='card-body p-9'>
              {/* Blocco 3: Dettagli di pagamento e date ordine */}
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Metodo di pagamento</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Metodo_pagamento === null ? "-" : currentOrderDetails.Metodo_pagamento}</span>
                    </div>

                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Modalità di pagamento</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Modalita_pagamento === null ? "-" : currentOrderDetails.Modalita_pagamento}</span>
                    </div>

                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Data entrata primo ordine</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Data_entrata_ordine === "Invalid Date" ? "-" : currentOrderDetails.Data_entrata_ordine}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Data inizio ultimo contratto</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Data_inizio_contratto === "Invalid Date" ? "-" : currentOrderDetails.Data_inizio_contratto}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Note</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Note === null ? "-" : currentOrderDetails.Note}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Creato il</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.createdAt === null ? "-" : currentOrderDetails.createdAt}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Modificato il</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.updatedAt === null ? "-" : currentOrderDetails.updatedAt}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Creato da</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.createdBy === null ? "-" : currentOrderDetails.createdBy}</span>
                    </div>

                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Modificato da</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.updatedBy === null ? "-" : currentOrderDetails.updatedBy}</span>
                    </div>

                </div>

            </div>
            
            <div className='card-body p-9'>
              {/* Blocco 4: Dettagli spedizione */}
              <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Corriere</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_corriere === null ? "-" : currentOrderDetails.Spedizione_corriere}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Costo</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_costo === null ? "-" : currentOrderDetails.Spedizione_costo+" €"}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Partenza stimata</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_partenza_stimata === "Invalid Date" ? "-" : currentOrderDetails.Spedizione_partenza_stimata}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Partenza effettiva</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_partenza_effettiva === "Invalid Date" ? "-" : currentOrderDetails.Spedizione_partenza_effettiva}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Arrivo<br></br>stimato</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_arrivo_stimato === "Invalid Date" ? "-" : currentOrderDetails.Spedizione_arrivo_stimato}</span>
                    </div>
                </div>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Arrivo <br></br>effettivo</label>

                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark'>{currentOrderDetails.Spedizione_arrivo_effettivo === "Invalid Date" ? "-" : currentOrderDetails.Spedizione_arrivo_effettivo}</span>
                    </div>
                </div>
            </div>
          </div>

        </div>
        ) : (
        <div className="row">
          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 2: Simulazioni scelte */}
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Data inizio contratto/i</label>

                    <div className='col-lg-8'>
                        {previousOrders.length === 0 ? (
                            <span className='fw-bolder fs-6 text-dark'>Nessun ordine precedente</span>
                        ) : (
                            <ul>
                            {previousOrders.map((order, index) => (
                              <li key={index} className='fw-bolder fs-6 text-dark'>{order.Data_inizio_contratto}</li>
                            ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
          </div>
        </div>
        )
        )}
          </div>
        </div>
      </Modal>,
    modalsRoot
  );
};

export { ViewDetailsOrders };