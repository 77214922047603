import * as Yup from 'yup'
import {validationConfig3} from '../../../../validationConfig';

export interface ICreateSubscriptionPlan {
    //Numero: string 
    Nome: string
    Numero_simulazioni: number
}

export const createSubscriptionPlanSchemas = Yup.object().shape({
  Nome: validationConfig3.Nome.required
    ? Yup.string().required('Nome obbligatoria').label('Nome')
    : Yup.string().label('Nome'),
  Numero_simulazioni: validationConfig3.Numero_simulazioni.required
    ? Yup.number().required('Numero simulazioni obbligatoria').label('Numero_simulazioni')
    : Yup.number().label('Numero_simulazioni'),
});

export const inits: ICreateSubscriptionPlan = {
    Nome: '',
    Numero_simulazioni: 0,
}
