import axios from '../config/axiosConfig';

/* Creazione nuovo piano di abbonamento */
export const createSubscriptionPlan = async (subscriptionPlanData) => {
  try {
    const response = await axios.post('/api/subscriptionPlans/create', subscriptionPlanData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating subscription plan:', error);
    throw error;
  }
};
