import axios from '../config/axiosConfig';

/* Creazione nuovo visore in stock */
export const createGoggleInStock = async (goggleInStockData) => {
  try {
    const response = await axios.post('/api/goggles/create', goggleInStockData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating goggle in stock:', error);
    throw error;
  }
};

/* Libro mastro */
export const statusGoggles = async () => {
  try {
    const response = await axios.post('/api/goggles/status');
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching goggles:', error);
    throw error;
  }
};

/* Elenco delle categorie di visori presenti */
export const listCategory = async () => {
  try {
    const response = await axios.post('/api/category/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching category: ', error);
    throw error;
  }
};

/* Dettaglio visore */
export const goggleDetails = async (id) => {
  try {
    const response = await axios.get(`/api/goggles/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status goggles: details', error);
    throw error;
  }
};

/* Cambia categoria di un visore */
export const transferGoggle = async (goggleId, categoryId) => {
  try {
    const response = await axios.put(`/api/goggles/transferGoggle/${goggleId}`, { categoryId });
    return response;
  } catch (error) {
    console.error('Error transfering goggles: ', error);
    throw error;
  }
};

/* Aggiorna le info riguardante l'account associato al visore */
export const updateAccount = async (goggleId, email, passwordd) => {
  try {
    const response = await axios.put(`/api/vraccounts/updateAccount/${goggleId}`, { email, passwordd });
    return response;
  } catch (error) {
    console.error('Error updating goggles account: ', error);
    throw error;
  }
};

/* Aggiorna le info riguardante l'account associato al visore */
export const updateHolder = async (goggleId, possessore, numero, remoteDeviceApp) => {
  try {
    const response = await axios.put(`/api/goggles/updateHolder/${goggleId}`, { possessore, numero, remoteDeviceApp });
    return response;
  } catch (error) {
    console.error('Error updating goggles holder: ', error);
    throw error;
  }
};

