/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {updateHolder} from '../../../../api/gogglesApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

type OptionType = {
  value: number;
  label: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateHolder = ({ show, handleClose, recordId }: Props) => {
  const [possessore, setPossessore] = useState<string | null>(null);
  const [numero, setNumero] = useState<string | null>(null);
  const [remoteDeviceApp, setRemoteDeviceApp] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [modalMessage, setModalMessage] = useState('');

  const handleRadioChange = (value: string) => {
    setRemoteDeviceApp(value);
  };

  const handleUpdateHolder = async () => {
    try {
      const response = await updateHolder(recordId, possessore, numero, remoteDeviceApp);
      setPossessore(null); 
      setNumero(null);
      setRemoteDeviceApp(null);
      handleClose();
      if (response.status === 201) {
        setModalMessage(response.data);
      }
      setShowConfirmModal(true);
    } catch (error: any) {
      console.error('Errore nella chiamata API:', error);
      if (error.response) {
        // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
        if (error.response.status === 400) {
          setModalMessage(error.response.data);
        } else {
          setModalMessage(error.response.data);
        }
      } else if (error.request) {
        // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
        setModalMessage(error.response.data);
      } else {
        // Qualcosa è andato storto nella creazione della richiesta
        setModalMessage(error.response.data);
      }
      setShowConfirmModal(true);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_update_delivery_effective"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Aggiornamento informazioni visore</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <label htmlFor="possessore" className='form-label'>Possessore: </label>
          <input
            type="text"
            id="possessore"
            value={possessore || ''}
            onChange={event => setPossessore(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label htmlFor="numero" className='form-label'>Numero di bollino: </label>
          <input
            type="text"
            id="numero"
            value={numero || ''}
            onChange={event => setNumero(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label className='d-flex align-items-center form-label mb-5'>
          Remote device app:
        </label>
          <div className='row mb-2' data-kt-buttons='true'>    
              <div className='col'>
                <input 
                    type="radio" 
                    id="MXR" 
                    name="remoteDeviceApp" 
                    value="MXR" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="MXR" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Manage XR</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="AXR" 
                    name="remoteDeviceApp" 
                    value="AXR" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="AXR" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Arbor XR</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="AppLab" 
                    name="remoteDeviceApp" 
                    value="AppLab" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="AppLab" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>AppLab</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="No" 
                    name="remoteDeviceApp" 
                    value="No" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="No" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>No</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="Invariato" 
                    name="remoteDeviceApp"
                    value="Invariato" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Invariato" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Invariato</span>
                </label>
              </div>
          </div>
        <br></br><br></br>
        <button className='btn btn-lg btn-light-primary me-3' onClick={handleUpdateHolder}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Aggiornamento informazioni visore</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>{modalMessage}</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { UpdateHolder };