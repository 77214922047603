/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {renewOrderSameConditions} from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

type OptionType = {
  value: number;
  label: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const RenewOrderSameConditions = ({ show, handleClose, recordId, numGoggles, numGogglesAssigned }: Props) => {
  const [RenewalStartDate, setRenewalStartDate] = useState<string | null>(null);
  const [RenewalPaymentMethod, setRenewalPaymentMethod] = useState<string | null>(null);
  const [RenewalPaymentMode, setRenewalPaymentMode] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  
  const handleRadioChange = (value: string) => {
    setRenewalPaymentMethod(value);
  };
  
  const handleRadioChange2 = (value: string) => {
    setRenewalPaymentMode(value);
  };

  const handleRenewOrderSameConditions = async () => {
    try {
      await renewOrderSameConditions(recordId, RenewalStartDate, RenewalPaymentMethod, RenewalPaymentMode);
      handleClose();
      setShowConfirmModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_order_delivered"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Rinnovo alle stesse condizioni</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-scaduto" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <label htmlFor="renewalStartDate" className='form-label required'>Data partenza rinnovo: </label>
          <input 
            type="date"
            id="renewalStartDate"
            value={RenewalStartDate || ''}
            onChange={event => setRenewalStartDate(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Metodo di pagamento
        </label>
          <div className='row mb-2' data-kt-buttons='true'>
              <div className='col'>
                <input 
                    type="radio" 
                    id="Carta" 
                    name="renewPaymentMethod" 
                    value="Carta" 
                    onChange={event => handleRadioChange(event.target.value)} 
                    className='btn-check'
                />
                <label htmlFor="Carta" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Carta</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="Paypal" 
                    name="renewPaymentMethod" 
                    value="Paypal" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Paypal" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Paypal</span>
                </label>
              </div>
			  
			  <div className='col'>
                <input 
                    type="radio" 
                    id="Bonifico" 
                    name="renewPaymentMethod" 
                    value="Bonifico" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Bonifico" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Bonifico</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="Ri.Ba" 
                    name="renewPaymentMethod" 
                    value="Ri.Ba" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Ri.Ba" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Ri.Ba</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="RID" 
                    name="renewPaymentMethod" 
                    value="RID" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="RID" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>RID</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="StessoDiPrima" 
                    name="renewPaymentMethod" 
                    value="StessoDiPrima" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="StessoDiPrima" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-4'>Invariato</span>
                </label>
              </div>

          </div>
        <br></br><br></br>
		<label className='d-flex align-items-center form-label mb-5 required'>
          Modalità di pagamento
        </label>
          <div className='row mb-2' data-kt-buttons='true'>    
          <div className='col'>
                <input 
                    type="radio" 
                    id="UnicaSoluzione" 
                    name="renewalPaymentMode" 
                    value="Unica soluzione" 
                    onChange={event => handleRadioChange2(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="UnicaSoluzione" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Unica soluzione</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="Mensile" 
                    name="renewalPaymentMode" 
                    value="Mensile" 
                    onChange={event => handleRadioChange2(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Mensile" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Mensile</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="StessaDiPrima" 
                    name="renewalPaymentMode" 
                    value="StessaDiPrima" 
                    onChange={event => handleRadioChange2(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="StessaDiPrima" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Invariata</span>
                </label>
              </div>
          </div>
        <br></br><br></br>
        <button className='btn btn-lg btn-light-scaduto me-3' onClick={handleRenewOrderSameConditions}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Rinnovo ordine alle stesse condizioni</h2>
        <div className="btn btn-sm btn-icon btn-active-color-scaduto" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>L'ordine è stato rinnovato con successo!</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-scaduto me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { RenewOrderSameConditions };