import React, { FC, useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext, FormikValues } from 'formik';
import { listGoggleModels } from '../../../../../api/ordersApi';

const Step2: FC = () => {
  
  const [goggleModelList, setGoggleModelList] = useState<any[]>([]);
  const [selectedGoggleModelIds, setSelectedGoggleModelIds] = useState<number[]>([]);
  const [selectedGoggleModelQuantities, setSelectedGoggleModelQuantities] = useState<{[key: number]: number}>({});

  // Usa Formik per accedere al valore del campo "Quantita_visori"
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const quantity = Number(values.Quantita_visori);

  useEffect(() => {
    const fetchSimulazioni = async () => {
      try {
        const response = await listGoggleModels();
        setGoggleModelList(response);
      } catch (error) {
        console.error('Errore nel recupero dei modelli di visore:', error);
      }
    };
    fetchSimulazioni();
  }, []);

  useEffect(() => {
    // Resetta l'array ogni volta che cambia la quantità
    setSelectedGoggleModelIds([]);
  }, [quantity]);

  useEffect(() => {
    // Aggiorna il campo Formik solo quando selectedGoggleModelIds cambia.
    setFieldValue('Modello_visore', selectedGoggleModelIds);
  }, [selectedGoggleModelIds, setFieldValue]);

  const incrementModel = (modelId: number) => {
    // Gestisci l'incremento del modello in modo asincrono o basato su stato.
    setSelectedGoggleModelQuantities(prev => ({
      ...prev,
      [modelId]: (prev[modelId] || 0) + 1
    }));
    // Aggiorna selectedGoggleModelIds in modo reattivo.
    setSelectedGoggleModelIds(prev => [...prev, modelId]);
  };
  
  const decrementModel = (modelId: number) => {
    // Gestisci il decremento del modello in modo asincrono o basato su stato.
    setSelectedGoggleModelQuantities(prev => {
      const newQuantity = Math.max((prev[modelId] || 0) - 1, 0);
      if (newQuantity > 0) {
        return { ...prev, [modelId]: newQuantity };
      } else {
        const { [modelId]: _, ...rest } = prev;
        return rest;
      }
    });
    // Aggiorna selectedGoggleModelIds in modo reattivo.
    setSelectedGoggleModelIds(prev => prev.filter(id => id !== modelId));
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Prodotti</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/* Inizio modello visore */}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Modello visore
        </label>

        <div>
          {goggleModelList.map((modello) => (
            <div key={modello.ID_Visore} className="modello-row">
              <span>{modello.Modello}</span>
              <button type="button" className="btn btn-icon btn-active-color-gray-700" onClick={(e) => decrementModel(modello.ID_Visore)}>-</button>
              <span>{selectedGoggleModelQuantities[modello.ID_Visore] || 0}</span>
              <button type="button" className="btn btn-icon btn-active-color-gray-700" onClick={(e) => incrementModel(modello.ID_Visore)}>+</button>
            </div>
          ))}
        </div>

        <div className='form-text'>
            {/* Visualizza il numero dei visori selezionati */}
            Visori selezionati: {Object.values(selectedGoggleModelQuantities).reduce((a, b) => a + b, 0)}
        </div>

        {/* Sezione errore */}
        <div className='fv-row mb-10'>
          <div className='text-danger mt-2'>
            <ErrorMessage name='Modello_visore' />
          </div>
        </div>

      </div>
      {/* Fine modello visore */}
    </div>
  );
};

export { Step2 };