import React, { FC, useState } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const Step4: FC = () => {
  const [IndirizzoSpedizioneDiversoDaFatturazione, setIndirizzoSpedizioneDiversoDaFatturazione] = useState(false);
  const { setFieldValue } = useFormikContext();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue("IndirizzoSpedizioneDiversoDaFatturazione", checked);
    setIndirizzoSpedizioneDiversoDaFatturazione(checked);
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Indirizzo di spedizione</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/* Indirizzo */}
      <div className='fv-row mb-10'>
        <label htmlFor='Indirizzo_sped' className='form-label required'>
          Indirizzo
        </label>
        <Field
          type='text'
          id='Indirizzo_sped'
          name='Indirizzo_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Indirizzo_sped' />
          </div>
        
      </div>

      {/* Appartamento_Interno_Scala */}
      <div className='fv-row mb-10'>
        <label htmlFor='Appartamento_Interno_Scala_sped' className='form-label'>
          Appartamento, Interno, Scala
        </label>
        <Field
          type='text'
          id='Appartamento_Interno_Scala_sped'
          name='Appartamento_Interno_Scala_sped'
          className='form-control form-control-lg form-control-solid'
        />

        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Appartamento_Interno_Scala_sped' />
          </div>
        
      </div>

      {/* Citta */}
      <div className='fv-row mb-10'>
        <label htmlFor='Citta_sped' className='form-label required'>
          Citta
        </label>
        <Field
          type='text'
          id='Citta_sped'
          name='Citta_sped'
          className='form-control form-control-lg form-control-solid'
        />

        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Citta_sped' />
          </div>
        
      </div>

      {/* Provincia */}
      <div className='fv-row mb-10'>
        <label htmlFor='Provincia_sped' className='form-label required'>
          Provincia
        </label>
        <Field
          type='text'
          id='Provincia_sped'
          name='Provincia_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Provincia_sped' />
          </div>
        
      </div>

      {/* CAP */}
      <div className='fv-row mb-10'>
        <label htmlFor='CAP_sped' className='form-label required'>
          CAP
        </label>
        <Field
          type='text'
          id='CAP_sped'
          name='CAP_sped'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='CAP_sped' />
          </div>
        
      </div>

      {/* Checkbox per l'indirizzo di fatturazione */}
      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <label htmlFor='IndirizzoSpedizioneDiversoDaFatturazione' className='form-check form-check-custom form-check-solid align-items-start'>
              <input
                className='form-check-input me-3'
                type='checkbox'
                id='IndirizzoSpedizioneDiversoDaFatturazione'
                name='IndirizzoSpedizioneDiversoDaFatturazione'
                checked={IndirizzoSpedizioneDiversoDaFatturazione}
                onChange={handleCheckboxChange}
              />
              <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Indirizzo di fatturazione diverso da quello di spedizione</span>
                <span className='text-muted fs-6'></span>
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* Form per l'indirizzo di fatturazione */}
      {IndirizzoSpedizioneDiversoDaFatturazione && (
        <div>
          <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder text-dark'>Indirizzo di fatturazione</h2>
            <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
          </div>
            {/* Indirizzo */}
            <div className='fv-row mb-10'>
              <label htmlFor='Indirizzo_fatt' className='form-label required'>
                Indirizzo
              </label>
              <Field
                type='text'
                id='Indirizzo_fatt'
                name='Indirizzo_fatt'
                className='form-control form-control-lg form-control-solid'
              />
              
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Indirizzo_fatt' />
                </div>
              
            </div>

            {/* Appartamento_Interno_Scala */}
            <div className='fv-row mb-10'>
              <label htmlFor='Appartamento_Interno_Scala_fatt' className='form-label'>
                Appartamento, Interno, Scala
              </label>
              <Field
                type='text'
                id='Appartamento_Interno_Scala_fatt'
                name='Appartamento_Interno_Scala_fatt'
                className='form-control form-control-lg form-control-solid'
              />
     
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Appartamento_Interno_Scala_fatt' />
                </div>
              
            </div>

            {/* Citta */}
            <div className='fv-row mb-10'>
              <label htmlFor='Citta_fatt' className='form-label required'>
                Citta
              </label>
              <Field
                type='text'
                id='Citta_fatt'
                name='Citta_fatt'
                className='form-control form-control-lg form-control-solid'
              />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='Citta_fatt' />
                </div>
              
            </div>

            {/* Provincia */}
            <div className='fv-row mb-10'>
              <label htmlFor='Provincia_fatt' className='form-label required'>
                Provincia
              </label>
              <Field
                type='text'
                id='Provincia_fatt'
                name='Provincia_fatt'
                className='form-control form-control-lg form-control-solid'
              />
              
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Provincia_fatt' />
                </div>
              
            </div>

            {/* CAP */}
            <div className='fv-row mb-10'>
              <label htmlFor='CAP_fatt' className='form-label required'>
                CAP
              </label>
              <Field
                type='text'
                id='CAP_fatt'
                name='CAP_fatt'
                className='form-control form-control-lg form-control-solid'
              />
              
                <div className='text-danger mt-2'>
                  <ErrorMessage name='CAP_fatt' />
                </div>
            </div>
        </div>
      )}
    </div>
);
};

export { Step4 };
