/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import { Field, ErrorMessage, useFormikContext, FormikValues } from 'formik';

const Step5_2: FC = () => {

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  function convertISOToYMD(isoString:any) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    // getMonth() restituisce un numero da 0 (gennaio) a 11 (dicembre), quindi aggiungi 1.
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  // Casting formato date
  useEffect(() => {
    if (values.Data_inizio_contratto) {
      setFieldValue("Data_inizio_contratto", convertISOToYMD(values.Data_inizio_contratto));
    }
    if (values.Data_entrata_ordine) {
      setFieldValue("Data_entrata_ordine", convertISOToYMD(values.Data_entrata_ordine));
    }
  }, [values.Data_inizio_contratto, values.Data_entrata_ordine, setFieldValue]);

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Fatturazione</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/*Inizio data entrata ordine*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Data_entrata_ordine' className='form-label required'>
          Data entrata ordine
        </label>
        <Field
          type='date'
          id='Data_entrata_ordine'
          name='Data_entrata_ordine'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Data_entrata_ordine' />
          </div>
        
      </div>
      {/*Fine data entrata ordine*/}

      {/*Inizio data inizio contratto*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Data_inizio_contratto' className='form-label required'>
          Data inizio contratto
        </label>
        <Field
          type='date'
          id='Data_inizio_contratto'
          name='Data_inizio_contratto'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Data_inizio_contratto' />
          </div>
        
      </div>
      {/*Fine data inizio contratto*/}

      {/*Inizio tipologia cessione*/}
      {/* <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label mb-5'>
          Tipologia di cessione
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Tipologia_cessione'
              value='Noleggio'
              id='kt_tipologia_cessione_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_tipologia_cessione_select_1'
            >
              <span className='fw-bolder fs-3'>Noleggio</span>
            </label>
          </div>

          
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Tipologia_cessione'
              value='Vendita'
              id='kt_tipologia_cessione_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_tipologia_cessione_select_2'
            >
              <span className='fw-bolder fs-3'>Vendita</span>
            </label>
          </div>

          
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Tipologia_cessione'
              value='Comprati loro'
              id='kt_tipologia_cessione_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_tipologia_cessione_select_3'
            >
              <span className='fw-bolder fs-3'>Comprati loro</span>
            </label>
          </div>

        </div>
      </div>*/}
      {/*Fine tipologia cessione*/}

      {/* Inizio metodo pagamento */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Metodo di pagamento
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          {/*Radio button 1*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='Carta'
              id='kt_metodo_pagamento_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_1'
            >
              <span className='fw-bolder fs-3'>Carta</span>
            </label>
          </div>

          {/*Radio button 2*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='Paypal'
              id='kt_metodo_pagamento_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_2'
            >
              <span className='fw-bolder fs-3'>Paypal</span>
            </label>
          </div>

          {/*Radio button 3*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='Bonifico'
              id='kt_metodo_pagamento_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_3'
            >
              <span className='fw-bolder fs-3'>Bonifico</span>
            </label>
          </div>

          {/*Radio button 4*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='Ri.Ba'
              id='kt_metodo_pagamento_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_4'
            >
              <span className='fw-bolder fs-3'>Ri.Ba</span>
            </label>
          </div>

          {/*Radio button 5*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='RID'
              id='kt_metodo_pagamento_select_5'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_5'
            >
              <span className='fw-bolder fs-3'>RID</span>
            </label>
          </div>

          {/*Radio button 6*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Metodo_pagamento'
              value='SDD'
              id='kt_metodo_pagamento_select_6'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_metodo_pagamento_select_6'
            >
              <span className='fw-bolder fs-3'>SDD</span>
            </label>
          </div>

        </div>

      </div>
      {/*Fine metodo pagamento*/}

      {/*Inizio modalita pagamento*/}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Modalità di pagamento
        </label>
        
        <div className='row mb-2' data-kt-buttons='true'>
          {/*Radio button 1*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Modalita_pagamento'
              value='Unica soluzione'
              id='kt_modalita_pagamento_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_modalita_pagamento_select_1'
            >
              <span className='fw-bolder fs-3'>Unica soluzione</span>
            </label>
          </div>

          {/*Radio button 2*/}
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='Modalita_pagamento'
              value='Mensile'
              id='kt_modalita_pagamento_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_modalita_pagamento_select_2'
            >
              <span className='fw-bolder fs-3'>Mensile</span>
            </label>
          </div>
    
        </div>

        <div className='form-text'>
          
        </div>
      </div>
      {/*Fine modalita pagamento*/}

    </div>
  )
}

export {Step5_2}