/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import {statusGoggles} from '../../../../api/gogglesApi'
import {ViewDetailsGoggles} from '../../modals/view-details-goggles/ViewDetailsGoggles'
import {UpdateAccount} from '../../modals/update-account/UpdateAccount'
import {UpdateHolder} from '../../modals/update-holder/UpdateHolder'
import {TransferGoggle} from '../../modals/transfer-goggle/TransferGoggle'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import './CustomWidget2_2.css';

dayjs.extend(localizedFormat);
dayjs.locale('it');

interface MyData {
  ID_Visore_in_stock: number
  Numero: string
  Nome_cliente: string
  Data_acquisto: string
  Seriale_scatola: string
  Seriale_visore: string
  Nome_categoria: string
  Modello: string
  Prezzo: number
  Nome_venditore: string
  Nazione_venditore: string
  Possessore: string
  Remote_device_app: string
  Tipologia_cessione: string
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

const CustomWidget2_2: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]); // New state for filtered data
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const debouncedSearchTerm = useDebounce(searchTerm, 150); // Debounce search term
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'Numero', direction: 'asc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Numero di elementi per pagina

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [goggleStatus, setGoggleStatus] = useState<string>('Nero');

  const [showUpdateAccount, setShowUpdateAccount] = useState(false);
  const [showUpdateHolder, setShowUpdateHolder] = useState(false);
  const [showTransferGoggle, setShowTransferGoggle] = useState(false);

  const [numGoggles, setNumGoggles] = useState<number | null>(null);
  const [numGogglesAssigned, setNumGogglesAssigned] = useState<number | null>(null);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      // Verifica se la chiave è "Numero" e converti i valori in numeri prima di confrontarli
      if (sortConfig.key === 'Numero') {
        const aValue = Number(a.Numero) || 0; // Usa 0 se il Numero non può essere convertito in un numero
        const bValue = Number(b.Numero) || 0; // Usa 0 se il Numero non può essere convertito in un numero
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } 
      // Per tutti gli altri campi, usa il confronto di stringhe come prima
      else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  }, [filteredData, sortConfig]);

  const handleRowClick = (action: string, recordId: number) => {
    setSelectedRecordId(recordId);
    setNumGoggles(numGoggles);
    setNumGogglesAssigned(numGogglesAssigned);
    if(action === 'view-details') {
      setShowModal(true);
    } else if(action === 'update-account') {
      setShowUpdateAccount(true);
    } else if(action === 'update-holder' && goggleStatus !== 'Nero') {
      setShowUpdateHolder(true);
    }
    else if(action === 'transfer-goggle') {
      setShowTransferGoggle(true);
    }
  };

  const handleCloseShowUpdateAccount = () => {
    setShowUpdateAccount(false);
  };

  const handleCloseShowUpdateHolder = () => {
    setShowUpdateHolder(false);
  };

  const handleCloseShowTransferGoggle = () => {
    setShowTransferGoggle(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [stateCounts, setStateCounts] = useState<{ [key: string]: number }>({});
  const [allData, setAllData] = useState<MyData[]>([]);

  const fetchData = async () => {
    try {
      let response = await statusGoggles();

      //Casto le date da formato americano a italiano
      for (let i = 0; i < response.length; i++) {
        let Data_acquisto_US = dayjs(response[i].Data_acquisto);
        response[i].Data_acquisto = Data_acquisto_US.format('LL');
        
        // Dividi i dati in base a "Tipologia_cessione"
        if (response[i].Nome_categoria === 'Nero' && response[i].Tipologia_cessione === 'Vendita') {
          response[i].Nome_categoria = 'Vendita';
        }
      }

      // Calcola il conteggio per ogni stato
      const counts: { [key: string]: number } = {};
      response.forEach((goggle: MyData) => {
        counts[goggle.Nome_categoria] = (counts[goggle.Nome_categoria] || 0) + 1;
      });

      // Imposta i conteggi
      setStateCounts(counts);

      // Imposta tutti i dati
      setAllData(response);

      if(goggleStatus !== null) {
        response = response.filter((goggle: MyData) => goggle.Nome_categoria === goggleStatus);
      }

      // Imposta i dati
      setData(response);
    } catch (error) {
      console.error(error);
    }
};

  useEffect(() => {
    fetchData();
  }, [goggleStatus]);

  // useEffect per gestire la barra di ricerca
  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(goggle => {
        return Object.values(goggle).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  // Nuova funzione per gestire il cambio dello stato del visore
  const handleGoggleStatusChange = (status: string) => {
    setCurrentPage(1);
    setGoggleStatus(status);
  };

  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  const totalGoggles = useMemo(() => {
    // Filtra i dati per escludere la categoria "Vendita"
    const filteredData = allData.filter(item => item.Nome_categoria !== 'Vendita');
    return filteredData.length;
  }, [allData]);
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Total Goggles */}
        <div className='me-10'>
          <span className='fw-bold'>Visori totali nostri: </span>{totalGoggles}
        </div>
        {/* end::Total Goggles */}
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3' onClick={exportDataToCSV}>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
      {/* end::Card toolbar */}
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Libro mastro</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-nero fw-bold px-4 me-1 ${goggleStatus === 'Vendita' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Vendita')}
              >
                Vendita ({stateCounts['Vendita'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-nero fw-bold px-4 me-1 ${goggleStatus === 'Nero' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Nero')}
              >
                Noleggio ({stateCounts['Nero'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-spedito fw-bold px-4 me-1 ${goggleStatus === 'Blu' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Blu')}
              >
                Prove gratuite ({stateCounts['Blu'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-attivo fw-bold px-4 me-1 ${goggleStatus === 'Verde' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Verde')}
              >
                Fiere/Dimostrazioni ({stateCounts['Verde'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-attivo fw-bold px-4 me-1 ${goggleStatus === 'Abo' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Abo')}
              >
                Valigia Abo ({stateCounts['Abo'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-ricevuto fw-bold px-4 me-1 ${goggleStatus === 'Rosso' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('Rosso')}
              >
                Uso interno ({stateCounts['Rosso'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-inattivo fw-bold px-4 me-1 ${goggleStatus === 'In stock' ? 'active' : ''}`}
                onClick={() => handleGoggleStatusChange('In stock')}
              >
                In stock ({stateCounts['In stock'] || 0})
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    {['Nero', 'Blu', 'Verde', 'Rosso'].includes(goggleStatus) && (
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Numero')}>Numero</th>
                    )}
                    {['Vendita', 'Nero'].includes(goggleStatus) && (
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Nome_cliente')}>Cliente</th>
                    )}
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Data_acquisto')}>Data acquisto</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Seriale_scatola')}>Seriale scatola</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Seriale_visore')}>Seriale visore</th>
                    <th className='p-0 min-w-100px th-padding text-dark fw-bold' onClick={() => changeSort('Modello')}>Modello</th>   
                    {['Verde', 'Rosso', 'In stock'].includes(goggleStatus) && (
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Possessore')}>Possessore</th>
                    )}
                    <th className='p-0 min-w-50px th-padding text-dark fw-bold' onClick={() => changeSort('Remote_device_app')}>RDA</th>
                    {/*<th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Nome_categoria')}>Categoria</th>*/}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Visore_in_stock}>
                      <td></td>
                      {['Nero', 'Blu', 'Verde', 'Rosso'].includes(goggleStatus) && (
                      <td className='text-muted fw-semibold'>{item.Numero === null ? "-" : item.Numero}</td>
                      )}
                      {['Vendita', 'Nero'].includes(goggleStatus) && (
                      <td className='text-muted fw-semibold'>{item.Nome_cliente === null ? "-" : item.Nome_cliente}</td>
                      )}
                      <td className='text-muted fw-semibold'>{item.Data_acquisto === "Invalid Date" ? "-" : item.Data_acquisto}</td>
                      <td className='text-muted fw-semibold'>{item.Seriale_scatola === null ? "-" : item.Seriale_scatola}</td>
                      <td className='text-muted fw-semibold'>{item.Seriale_visore === null ? "-" : item.Seriale_visore}</td>
                      <td className='text-muted fw-semibold'>{item.Modello}</td>
                      {['Verde', 'Rosso', 'In stock'].includes(goggleStatus) && (
                      <td className='text-muted fw-semibold'>{item.Possessore === null ? "-" : item.Possessore}</td>
                      )}
                      <td className='text-muted fw-semibold'>{item.Remote_device_app === null ? "-" : item.Remote_device_app}</td>
                      {/*<td className='text-muted fw-semibold'>
                        <span className={`badge ${item.Nome_categoria === "Nero" ? "badge-light-nero" : ""} ${item.Nome_categoria === "Blu" ? "badge-light-spedito" : ""} 
                        ${item.Nome_categoria === "Verde" ? "badge-light-attivo" : ""} ${item.Nome_categoria === "Rosso" ? "badge-light-ricevuto" : ""} 
                        ${item.Nome_categoria === "Abo" ? "badge-light-attivo" : ""} ${item.Nome_categoria === "In stock" ? "badge-light-inattivo" : ""} 
                        `}>
                          {item.Nome_categoria}
                        </span>
                      </td>*/}
                      <td className='text-muted fw-semibold'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen052.svg'
                              className='svg-icon-2'
                            />
                          </button>
                          <ul className='dropdown-menu custom-dropdown-menu'>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('view-details', item.ID_Visore_in_stock)}>
                                Dettagli visore
                              </a>
                            </li>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('transfer-goggle', item.ID_Visore_in_stock)}>
                                Cambia categoria
                              </a>
                            </li>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('update-account', item.ID_Visore_in_stock)}>
                                Aggiorna account
                              </a>
                            </li>
                            {item.Nome_categoria !== 'Nero' && item.Nome_categoria !== 'Vendita' && (
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('update-holder', item.ID_Visore_in_stock)}>
                                Aggiorna informazioni
                              </a>
                            </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
            <UsersListPagination
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
          {/* end::Tap pane */}

        </div>
      </div>
      {/* end::Body */}
      {selectedRecordId !== null && (
        <>
        <ViewDetailsGoggles show={showModal} handleClose={handleCloseModal} recordId={selectedRecordId}/>
        <UpdateAccount show={showUpdateAccount} handleClose={handleCloseShowUpdateAccount} recordId={selectedRecordId}/>
        <UpdateHolder show={showUpdateHolder} handleClose={handleCloseShowUpdateHolder} recordId={selectedRecordId}/>
        <TransferGoggle show={showTransferGoggle} handleClose={handleCloseShowTransferGoggle} recordId={selectedRecordId}/>
        {/*
        <UpdateShipment show={showUpdateShipmentModal} handleClose={handleCloseUpdateShipmentModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <OrderDelivered show={showOrderDeliveredModal} handleClose={handleCloseOrderDeliveredModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <RenewOrderSameConditions show={showRenewOrderSameConditionsModal} handleClose={handleCloseRenewOrderSameConditionsModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <EndContract show={showEndContractModal} handleClose={handleCloseEndContractModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        */}
        </>
      )}
    </div>
  )
}

export {CustomWidget2_2}