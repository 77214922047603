/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import {statusOrders} from '../../../../api/ordersApi'
import {ViewDetailsOrders} from '../../modals/view-details-orders/ViewDetailsOrders'
import {EditOrder1} from '../../modals/edit-order/EditOrder_1'
import {EditOrder2} from '../../modals/edit-order/EditOrder_2'
import {AssignGogglesToOrder} from '../../modals/assign-goggles-to-order/AssignGogglesToOrder'
import {InsertShipment} from '../../modals/insert-shipment/InsertShipment'
import {UpdateShipment} from '../../modals/update-shipment/UpdateShipment'
import {OrderDelivered} from '../../modals/order-delivered/OrderDelivered'
import {RenewOrderSameConditions} from '../../modals/renew-order-same-conditions/RenewOrderSameConditions'
import {RenewOrderDifferentConditions} from '../../modals/renew-order-different-conditions/RenewOrderDifferentConditions'
import {EndContract} from '../../modals/end-contract/EndContract'
import {ReturnGoggles} from '../../modals/return-goggles/ReturnGoggles'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'

import './CustomWidget2.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

interface MyData {
  ID_Ordine: number
  ID_Cliente: number
  Ragione_sociale: string
  Tipologia_cessione: string
  Numero_visori: number
  Numero_visori_assegnati: number
  Numero_visori_ritornati: number
  Piano_Abbonamento: string
  Durata: number
  Importo_totale: number
  Importo_effettivo: number
  Data_fine_contratto: string
  Stato_Ordine: string
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

const CustomWidget2: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]); // New state for filtered data
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const debouncedSearchTerm = useDebounce(searchTerm, 150); // Debounce search term
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'Ragione_sociale', direction: 'asc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Numero di elementi per pagina

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [showEditModal1, setShowEditModal1] = useState(false);
  const [showEditModal2, setShowEditModal2] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [orderStatus, setOrderStatus] = useState<string>('Ricevuto');

  const [showAssignGogglesModal, setShowAssignGogglesModal] = useState(false);
  const [showInsertShipmentModal, setShowInsertShipment] = useState(false);
  const [showUpdateShipmentModal, setShowUpdateShipment] = useState(false);
  const [showOrderDeliveredModal, setShowOrderDelivered] = useState(false);
  const [showRenewOrderSameConditionsModal, setShowRenewOrderSameConditions] = useState(false);
  const [showRenewOrderDifferentConditionsModal, setShowRenewOrderDifferentConditions] = useState(false);
  const [showEndContractModal, setShowEndContract] = useState(false);
  const [showReturnGogglesModal, setShowReturnGoggles] = useState(false);

  const [numGoggles, setNumGoggles] = useState<number | null>(null);
  const [numGogglesAssigned, setNumGogglesAssigned] = useState<number | null>(null);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const handleRowClick = (action: string, recordId: number, numGoggles: number, numGogglesAssigned: number) => {
    setSelectedRecordId(recordId);
    setNumGoggles(numGoggles);
    setNumGogglesAssigned(numGogglesAssigned);
    if(action === 'view-details') {
      setShowModal(true);
    } else if(action === 'edit-order-1') {
      setShowEditModal1(true);
    } else if(action === 'edit-order-2') {
      setShowEditModal2(true);
    } else if(action === 'assign-goggles' && orderStatus === 'Ricevuto') {
      setShowAssignGogglesModal(true);
    }
    else if(action === 'insert-shipment' && orderStatus === 'In lavorazione') {
      setShowInsertShipment(true);
    }
    else if(action === 'update-shipment' && orderStatus === 'Pronto') {
      setShowUpdateShipment(true);
    }
    else if(action === 'order-delivered' && orderStatus === 'Spedito') {
      setShowOrderDelivered(true);
    }
    else if(action === 'renew-same-conditions' && orderStatus === 'Scaduto') {
      setShowRenewOrderSameConditions(true);
    }
    else if(action === 'renew-different-conditions' && orderStatus === 'Scaduto') {
      setShowRenewOrderDifferentConditions(true);
    }
    else if(action === 'end-contract' && orderStatus === 'Scaduto') {
      setShowEndContract(true);
    }
    else if(action === 'return-goggles' && orderStatus === 'Inattivo') {
      setShowReturnGoggles(true);
    }
  };

  const handleCloseAssignGogglesModal = () => {
    setShowAssignGogglesModal(false);
  };

  const handleCloseInsertShipmentModal = () => {
    setShowInsertShipment(false);
  };

  const handleCloseUpdateShipmentModal = () => {
    setShowUpdateShipment(false);
  };

  const handleCloseOrderDeliveredModal = () => {
    setShowOrderDelivered(false);
  };

  const handleCloseRenewOrderSameConditionsModal = () => {
    setShowRenewOrderSameConditions(false);
  };

  const handleCloseRenewOrderDifferentConditionsModal = () => {
    setShowRenewOrderDifferentConditions(false);
  };

  const handleCloseEndContractModal = () => {
    setShowEndContract(false);
  };

  const handleCloseReturnGogglesModal = () => {
    setShowReturnGoggles(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseEditModal1 = () => {
    setShowEditModal1(false);
  };

  const handleCloseEditModal2 = () => {
    setShowEditModal2(false);
  };

  const [stateCounts, setStateCounts] = useState<{ [key: string]: number }>({});
  const [allData, setAllData] = useState<MyData[]>([]);

  const fetchData = async () => {
    try {
      let response = await statusOrders();

      for (let i = 0; i < response.length; i++) {
        let Data_inizio_contratto_US = dayjs(response[i].Data_inizio_contratto);
        response[i].Data_inizio_contratto = Data_inizio_contratto_US.format('LL');

        let Data_fine_contratto_US = Data_inizio_contratto_US.add(response[i].Durata, 'month');
        response[i].Data_fine_contratto = Data_fine_contratto_US.format('LL');
      }
  
      // Calcola il conteggio per ogni stato
      const counts: { [key: string]: number } = {};
      response.forEach((order: MyData) => {
        counts[order.Stato_Ordine] = (counts[order.Stato_Ordine] || 0) + 1;
      });
  
      // Imposta i conteggi
      setStateCounts(counts);

      // Imposta tutti i dati
      setAllData(response);
  
      if(orderStatus !== null) {
        response = response.filter((order: MyData) => order.Stato_Ordine === orderStatus);
      }
  
      // Imposta i dati
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderStatus]);

  // useEffect per gestire la barra di ricerca
  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(order => {
        return Object.values(order).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  // Nuova funzione per gestire il cambio dello stato dell'ordine
  const handleOrderStatusChange = (status: string) => {
    setCurrentPage(1);
    setOrderStatus(status);
  };

  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData); // Usa filteredData qui
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  const totalGoggles = useMemo(() => {
    return allData
      .filter(order => order.Stato_Ordine === "Attivo" || order.Stato_Ordine === "In scadenza")
      .reduce((total, order) => total + order.Numero_visori_assegnati, 0);
  }, [allData]);

  const totalGogglesRent = useMemo(() => {
    return allData
      .filter(order => order.Tipologia_cessione === "Noleggio" && (order.Stato_Ordine === "Attivo" || order.Stato_Ordine === "In scadenza"))
      .reduce((total, order) => total + order.Numero_visori_assegnati, 0);
  }, [allData]);

  const totalGogglesSold = useMemo(() => {
    return allData
      .filter(order => order.Tipologia_cessione === "Vendita" && (order.Stato_Ordine === "Attivo" || order.Stato_Ordine === "In scadenza"))
      .reduce((total, order) => total + order.Numero_visori_assegnati, 0);
  }, [allData]);

  const totalGogglesBoughtByThem = useMemo(() => {
    return allData
      .filter(order => order.Tipologia_cessione === "Comprati loro" && (order.Stato_Ordine === "Attivo" || order.Stato_Ordine === "In scadenza"))
      .reduce((total, order) => total + order.Numero_visori_assegnati, 0);
  }, [allData]);
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Total Goggles */}
        <div className='me-10'>
          <span className='fw-bold'>Licenze totali attive: </span>{totalGoggles}
        </div>
        <div className='me-10'>
          <span className='fw-bold'>| </span>
        </div>
        <div className='me-10'>
          <span className='fw-bold'>Noleggiati: </span>{totalGogglesRent}
        </div>
        <div className='me-10'>
          <span className='fw-bold'>Venduti: </span>{totalGogglesSold}
        </div>
        <div className='me-10'>
          <span className='fw-bold'>Comprati dai clienti: </span>{totalGogglesBoughtByThem}
        </div>
        {/* end::Total Goggles */}
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3' onClick={exportDataToCSV}>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
      {/* end::Card toolbar */}
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Stato ordini</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-ricevuto fw-bold px-4 me-1 ${orderStatus === 'Ricevuto' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Ricevuto')}
              >
                Ricevuto ({stateCounts['Ricevuto'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-inlavorazione fw-bold px-4 me-1 ${orderStatus === 'In lavorazione' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('In lavorazione')}
              >
                In lavorazione ({stateCounts['In lavorazione'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-pronto fw-bold px-4 me-1 ${orderStatus === 'Pronto' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Pronto')}
              >
                Pronto ({stateCounts['Pronto'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-spedito fw-bold px-4 me-1 ${orderStatus === 'Spedito' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Spedito')}
              >
                Spedito ({stateCounts['Spedito'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-attivo fw-bold px-4 me-1 ${orderStatus === 'Attivo' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Attivo')}
              >
                Attivo ({stateCounts['Attivo'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-inscadenza fw-bold px-4 me-1 ${orderStatus === 'In scadenza' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('In scadenza')}
              >
                In scadenza ({stateCounts['In scadenza'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-scaduto fw-bold px-4 me-1 ${orderStatus === 'Scaduto' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Scaduto')}
              >
                Scaduto ({stateCounts['Scaduto'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-inattivo fw-bold px-4 me-1 ${orderStatus === 'Inattivo' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Inattivo')}
              >
                Inattivo ({stateCounts['Inattivo'] || 0})
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Ragione_sociale')}>Ragione sociale</th>
                    <th className='p-0 min-w-140px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Tipologia_cessione')}>Tipologia cessione</th>
                    <th className='p-0 min-w-140px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Numero_visori')}>N° licenze</th>
                    {['Ricevuto'].includes(orderStatus) && (
                    <th className='p-0 min-w-140px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Numero_visori_assegnati')}>N° visori assegnati</th>
                    )}
                    {['Inattivo'].includes(orderStatus) && (
                    <th className='p-0 min-w-140px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Numero_visori_ritornati')}>N° visori ritornati</th>
                    )}
                    <th className='p-0 min-w-110px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Piano_Abbonamento')}>Piano</th>
                    <th className='p-0 min-w-50px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Durata')}>Durata (mesi)</th>
                    {/*<th className='p-0 min-w-50px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Importo_totale')}>Prezzo di listino</th>*/}
                    <th className='p-0 min-w-50px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Importo_effettivo')}>Importo effettivo</th>
                    <th className='p-0 min-w-50px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Importo_effettivo')}>Data fine contratto</th>
                    {/*<th className='p-0 min-w-50px text-end th-padding text-dark fw-bold' onClick={() => changeSort('Stato_Ordine')}>Stato</th>*/}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Ordine}>
                      <td>
                      </td>
                      <td>{item.Ragione_sociale}</td>
                      <td className='text-end text-muted fw-semibold fw-underline'>{item.Tipologia_cessione}</td>
                      <td className='text-end text-muted fw-semibold fw-underline'>{item.Numero_visori}</td>
                      {['Ricevuto'].includes(orderStatus) && (
                      <td className='text-end text-muted fw-semibold fw-underline'>{item.Numero_visori_assegnati}</td>
                      )}
                      {['Inattivo'].includes(orderStatus) && (
                      <td className='text-end text-muted fw-semibold fw-underline'>{item.Numero_visori_ritornati}</td>
                      )}
                      <td className='text-end text-muted fw-semibold'>{item.Piano_Abbonamento}</td>
                      <td className='text-end text-muted fw-semibold'>{item.Durata}</td>
                      {/*<td className='text-end text-muted fw-semibold'>{item.Importo_totale} €</td>*/}
                      <td className='text-end text-muted fw-semibold'>{item.Importo_effettivo} €</td>
                      <td className='text-end text-muted fw-semibold'>{item.Data_fine_contratto === "Invalid Date" ? "Non inserita" : item.Data_fine_contratto}</td>
                      {/*<td className='text-end'>
                        <span className={`badge ${item.Stato_Ordine === "Ricevuto" ? "badge-light-ricevuto" : ""} ${item.Stato_Ordine === "In lavorazione" ? "badge-light-inlavorazione" : ""} 
                        ${item.Stato_Ordine === "Pronto" ? "badge-light-pronto" : ""} ${item.Stato_Ordine === "Spedito" ? "badge-light-spedito" : ""} ${item.Stato_Ordine === "Attivo" ? "badge-light-attivo" : ""} 
                        ${item.Stato_Ordine === "In scadenza" ? "badge-light-inscadenza" : ""} ${item.Stato_Ordine === "Scaduto" ? "badge-light-scaduto" : ""} 
                        ${item.Stato_Ordine === "Inattivo" ? "badge-light-inattivo" : ""}`}>
                          {item.Stato_Ordine}
                        </span>
                      </td>*/}
                      <td className='text-end'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen052.svg'
                              className='svg-icon-2'
                            />
                          </button>
                          <ul className='dropdown-menu custom-dropdown-menu'>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('view-details', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                Dettagli ordine
                              </a>
                            </li>
                            {(item.Stato_Ordine === 'Ricevuto') && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('edit-order-1', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Modifica ordine
                                </a>
                              </li>
                            )}
                            {(item.Stato_Ordine === 'Attivo' || item.Stato_Ordine === 'In scadenza') && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('edit-order-2', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Modifica ordine
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Ricevuto' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('assign-goggles', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Associa visori
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'In lavorazione' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('insert-shipment', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Inserisci spedizione
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Pronto' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('update-shipment', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Aggiorna spedizione
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Spedito' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('order-delivered', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Completa ordine
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Scaduto' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('renew-same-conditions', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Rinnovo stesse condizioni
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Scaduto' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('renew-different-conditions', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Rinnovo diverse condizioni
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Scaduto' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('end-contract', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Termina contratto
                                </a>
                              </li>
                            )}
                            {item.Stato_Ordine === 'Inattivo' && item.Tipologia_cessione === 'Noleggio' && item.Numero_visori!==item.Numero_visori_ritornati && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('return-goggles', item.ID_Ordine, item.Numero_visori, item.Numero_visori_assegnati)}>
                                  Rientro visori
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
            <UsersListPagination
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
          {/* end::Tap pane */}

        </div>
      </div>
      {/* end::Body */}
      {selectedRecordId !== null && (
        <>
        <ViewDetailsOrders show={showModal} handleClose={handleCloseModal} recordId={selectedRecordId}/>
        <EditOrder1 show={showEditModal1} handleClose={handleCloseEditModal1} recordId={selectedRecordId}/>
        <EditOrder2 show={showEditModal2} handleClose={handleCloseEditModal2} recordId={selectedRecordId}/>
        <AssignGogglesToOrder show={showAssignGogglesModal} handleClose={handleCloseAssignGogglesModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <InsertShipment show={showInsertShipmentModal} handleClose={handleCloseInsertShipmentModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <UpdateShipment show={showUpdateShipmentModal} handleClose={handleCloseUpdateShipmentModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <OrderDelivered show={showOrderDeliveredModal} handleClose={handleCloseOrderDeliveredModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <RenewOrderSameConditions show={showRenewOrderSameConditionsModal} handleClose={handleCloseRenewOrderSameConditionsModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <RenewOrderDifferentConditions show={showRenewOrderDifferentConditionsModal} handleClose={handleCloseRenewOrderDifferentConditionsModal} recordId={selectedRecordId}/>
        <EndContract show={showEndContractModal} handleClose={handleCloseEndContractModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        <ReturnGoggles show={showReturnGogglesModal} handleClose={handleCloseReturnGogglesModal} recordId={selectedRecordId} numGoggles={numGoggles} numGogglesAssigned={numGogglesAssigned}/>
        </>
      )}
    </div>
  )
}

export {CustomWidget2}
