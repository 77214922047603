/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {updateShipmentFreeTrial} from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateShipmentFreeTrial = ({ show, handleClose, recordId, numGoggles, numGogglesAssigned }: Props) => {
  const [effectiveDepartureDate, setEffectiveDepartureDate] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  

  const handleUpdateShipment = async () => {
    try {
      await updateShipmentFreeTrial(recordId, effectiveDepartureDate);
      handleClose();
      setShowConfirmModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_update_delivery_effective"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Inserisci la data effettiva di spedizione dell'ordine</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <label htmlFor="effective-departure-date" className='form-label required'>Data spedizione effettiva (se consegnato a mano, tutte le date di partenza e arrivo combacieranno)</label>
          <input
            type="date"
            id="effective-departure-date"
            value={effectiveDepartureDate || ''}
            onChange={event => setEffectiveDepartureDate(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <button className='btn btn-lg btn-light-pronto me-3' onClick={handleUpdateShipment}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Aggiornamento spedizione</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>L'aggiornamento della spedizione è andato a buon fine!</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { UpdateShipmentFreeTrial };