export interface ValidationRule {
    required: boolean;
    type: string;
  }
  
  export const validationConfig: { [key: string]: ValidationRule } = {
    // Cliente
    Ragione_sociale: {
      required: true,
      type: 'string',
    },
    P_IVA: {
      required: true,
      type: 'string',
    },
    CF: {
      required: false,
      type: 'string',
    },
    SDI: {
      required: false,
      type: 'string',
    },
    PEC: {
      required: false,
      type: 'string',
    },
    Email: {
      required: true,
      type: 'string',
    },
    Intermediario: {
      required: true,
      type: 'string',
    },
    // Prodotti
    Modello_visore: {
      required: true,
      type: 'array',
    },
    // Servizi
    Piano_abbonamento: {
      required: true,
      type: 'string',
    },
    Lunghezza_abbonamento: {
      required: true,
      type: 'number',
    },
    Simulazioni_scelte: {
      required: true,
      type: 'array',
    },
    Opzioni_aggiuntive_scelte: {
      required: true,
      type: 'array',
    },
    Importo_effettivo: {
      required: true,
      type: 'number',
    },
    Note_aggiuntive: {
      required: false,
      type: 'string',
    },
    // Indirizzi di spedizione
    Indirizzo_sped: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_sped: {
      required: false,
      type: 'string',
    },
    Citta_sped: {
      required: true,
      type: 'string',
    },
    Provincia_sped: {
      required: true,
      type: 'string',
    },
    CAP_sped: {
      required: true,
      type: 'string',
    },
    // Checkbox
    IndirizzoSpedizioneDiversoDaFatturazione: {
      required: false,
      type: 'boolean',
    },
    // Indirizzi di fatturazione
    Indirizzo_fatt: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_fatt: {
      required: true,
      type: 'string',
    },
    Citta_fatt: {
      required: true,
      type: 'string',
    },
    Provincia_fatt: {
      required: true,
      type: 'string',
    },
    CAP_fatt: {
      required: true,
      type: 'string',
    },
    // Fatturazione
    Data_entrata_ordine: {
      required: true,
      type: 'date',
    },
    Data_inizio_contratto: {
      required: true,
      type: 'date',
    },
    Tipologia_cessione: {
      required: true,
      type: 'string',
    },
    Metodo_pagamento: {
      required: true,
      type: 'string',
    },
    Modalita_pagamento: {
      required: true,
      type: 'string',
    },
  };

  export const validationConfig1_1: { [key: string]: ValidationRule } = {
    // Cliente
    Ragione_sociale: {
      required: true,
      type: 'string',
    },
    P_IVA: {
      required: true,
      type: 'string',
    },
    CF: {
      required: false,
      type: 'string',
    },
    SDI: {
      required: false,
      type: 'string',
    },
    PEC: {
      required: false,
      type: 'string',
    },
    Email: {
      required: true,
      type: 'string',
    },
    Intermediario: {
      required: true,
      type: 'string',
    },
    // Prodotti
    Modello_visore: {
      required: true,
      type: 'array',
    },
    // Servizi
    Piano_abbonamento: {
      required: true,
      type: 'string',
    },
    Lunghezza_abbonamento: {
      required: true,
      type: 'number',
    },
    Simulazioni_scelte: {
      required: true,
      type: 'array',
    },
    Opzioni_aggiuntive_scelte: {
      required: true,
      type: 'array',
    },
    Importo_effettivo: {
      required: true,
      type: 'number',
    },
    Note_aggiuntive: {
      required: false,
      type: 'string',
    },
    // Indirizzi di spedizione
    Indirizzo_sped: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_sped: {
      required: false,
      type: 'string',
    },
    Citta_sped: {
      required: true,
      type: 'string',
    },
    Provincia_sped: {
      required: true,
      type: 'string',
    },
    CAP_sped: {
      required: true,
      type: 'string',
    },
    // Checkbox
    IndirizzoSpedizioneDiversoDaFatturazione: {
      required: false,
      type: 'boolean',
    },
    // Indirizzi di fatturazione
    Indirizzo_fatt: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_fatt: {
      required: true,
      type: 'string',
    },
    Citta_fatt: {
      required: true,
      type: 'string',
    },
    Provincia_fatt: {
      required: true,
      type: 'string',
    },
    CAP_fatt: {
      required: true,
      type: 'string',
    },
    // Fatturazione
    Data_entrata_ordine: {
      required: true,
      type: 'date',
    },
    Data_inizio_contratto: {
      required: false,
      type: 'date',
    },
    Tipologia_cessione: {
      required: true,
      type: 'string',
    },
    Metodo_pagamento: {
      required: true,
      type: 'string',
    },
    Modalita_pagamento: {
      required: true,
      type: 'string',
    },
  };

  export const validationConfig1_2: { [key: string]: ValidationRule } = {
    // Cliente
    Ragione_sociale: {
      required: true,
      type: 'string',
    },
    P_IVA: {
      required: true,
      type: 'string',
    },
    CF: {
      required: false,
      type: 'string',
    },
    SDI: {
      required: false,
      type: 'string',
    },
    PEC: {
      required: false,
      type: 'string',
    },
    Email: {
      required: true,
      type: 'string',
    },
    Intermediario: {
      required: true,
      type: 'string',
    },
    Note_aggiuntive: {
      required: false,
      type: 'string',
    },
    Modello_visore: {
      required: true,
      type: 'string',
    },
    // Indirizzi di spedizione
    Indirizzo_sped: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_sped: {
      required: false,
      type: 'string',
    },
    Citta_sped: {
      required: true,
      type: 'string',
    },
    Provincia_sped: {
      required: true,
      type: 'string',
    },
    CAP_sped: {
      required: true,
      type: 'string',
    },
    // Checkbox
    IndirizzoSpedizioneDiversoDaFatturazione: {
      required: false,
      type: 'boolean',
    },
    // Indirizzi di fatturazione
    Indirizzo_fatt: {
      required: true,
      type: 'string',
    },
    Appartamento_Interno_Scala_fatt: {
      required: true,
      type: 'string',
    },
    Citta_fatt: {
      required: true,
      type: 'string',
    },
    Provincia_fatt: {
      required: true,
      type: 'string',
    },
    CAP_fatt: {
      required: true,
      type: 'string',
    },
    // Fatturazione
    Data_entrata_prova: {
      required: true,
      type: 'date',
    },
    Tipologia_cessione: {
      required: true,
      type: 'string',
    },
    Metodo_pagamento: {
      required: true,
      type: 'string',
    },
  };

  export const validationConfig2: { [key: string]: ValidationRule } = {
    Numero: {
      required: false,
      type: 'string',
    },
    Data_acquisto: {
      required: false,
      type: 'date',
    },
    Seriale_scatola: {
      required: false,
      type: 'string',
    },
    Seriale_visore: {
      required: false,
      type: 'string',
    },
    Possessore: {
      required: false,
      type: 'string',
    },
    Remote_device_app: {
      required: true,
      type: 'string',
    },
    COD_Visore: {
      required: false,
      type: 'number',
    },
    COD_Venditore: {
      required: false,
      type: 'number',
    },
    COD_VR_Account: {
      required: false,
      type: 'number',
    },
  };

  export const validationConfig3: { [key: string]: ValidationRule } = {
    Nome: {
      required: true,
      type: 'string',
    },
    Numero_simulazioni: {
      required: true,
      type: 'number',
    },
  };

  export const validationConfig4: { [key: string]: ValidationRule } = {
    Nome: {
      required: true,
      type: 'string',
    },
  };