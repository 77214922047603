/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect}  from 'react'
import { Field, ErrorMessage, FormikValues } from 'formik'
import Select from 'react-select'
import { useFormikContext } from 'formik';
import {listSimulations, listSubscriptionPlans, listAdditionalOptions } from '../../../../../api/ordersApi'


const Step3: FC = () => {

  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [simulationsList, setSimulationsList] = useState<any[]>([]);
  const [subscriptionPlansList, setSubscriptionPlansList] = useState<any[]>([]);
  const [selectedSimulation, setSelectedSimulation] = useState<string[]>([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>();
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [additionalOptions, setAdditionalOptions] = useState<any[]>([]);
  const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState<any[]>([]);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const response = await listSubscriptionPlans();
        setSubscriptionPlansList(response);
  
        // Trova il piano di abbonamento corrispondente all'ID nel tuo set di dati
        const selectedPlan = response.find((pianoAbbonamento: any) => pianoAbbonamento.ID_Piano_abbonamento === values.ID_Piano_abbonamento);
        if (selectedPlan) {
          setSelectedPlan({ value: selectedPlan.ID_Piano_abbonamento, label: selectedPlan.Nome,  Num_max_simulazioni: selectedPlan.Num_max_simulazioni});
        }
      } catch (error) {
        console.error('Errore nel recupero dei piani d\'abbonamento:', error);
      }
    };
    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    const fetchSimulazioni = async () => {
      try {
        const response2 = await listSimulations();
        setSimulationsList(response2);
  
        // Se values.ID_Simulazione è una stringa, la convertiamo in un array di numeri
        const ids = values.ID_Simulazioni ? values.ID_Simulazioni.split(",").map(Number) : [];
  
        // Trova i nomi delle simulazioni corrispondenti agli ID nel tuo set di dati
        const simulationNames = ids.map(
          (id: number) => response2.find((simulation: any) => simulation.ID_Simulazione === id)?.Nome
        );
        setSelectedSimulation(simulationNames);
      } catch (error) {
        console.error('Errore nel recupero delle simulazioni:', error);
      }
    };
    fetchSimulazioni();
  }, []);

  const handlePlanChange = (selectedOption: any) => {
    const plan = subscriptionPlansList.find(plan => plan.ID_Piano_abbonamento === selectedOption.value);
    if (plan) {
      setSelectedPlan({ 
        value: plan.ID_Piano_abbonamento, 
        label: plan.Nome,
        Num_max_simulazioni: plan.Num_max_simulazioni // Aggiungi questa linea
      });
      setSubscriptionPlan(plan.Nome);
      setFieldValue('Piano_abbonamento', plan.ID_Piano_abbonamento);
    }
    
    // Resetta le simulazioni selezionate quando cambia il piano di abbonamento
    setSelectedSimulation([]);
    setFieldValue('Simulazioni_scelte', []);
  
    // Se il piano selezionato è "Matrix", seleziona tutte le simulazioni
    if (plan.Nome === 'Matrix') {
      const allSimulations = simulationsList
        .filter(simulation => simulation.Tipologia === 'Pronto uso')
        .map(simulation => simulation.Nome);
      setSelectedSimulation(allSimulations);
      setFieldValue('Simulazioni_scelte', allSimulations);
    }
  };

  const handleSimulationChange = (selectedOptions: any) => {
    if (selectedOptions && Array.isArray(selectedOptions)) {
      if (selectedPlan && selectedPlan.Num_max_simulazioni && selectedOptions.length <= selectedPlan.Num_max_simulazioni) {
        const selectedIds = selectedOptions.map((option: any) => {
          const simulation = simulationsList.find(simulation => simulation.Nome === option.value);
          return simulation ? simulation.ID_Simulazione : null;
        }).filter(id => id != null);
        setSelectedSimulation(selectedOptions.map(option => option.value)); // Mantieni la logica attuale per l'UI
        setFieldValue('Simulazioni_scelte', selectedIds);
      } else if (!selectedPlan) {
        alert('Per favore, seleziona un piano di abbonamento prima di scegliere le simulazioni.');
      } else if (selectedPlan && selectedPlan.Num_max_simulazioni) {
        alert(`Non puoi selezionare più di ${selectedPlan.Num_max_simulazioni} simulazioni.`);
      } else {
        alert('Si è verificato un errore. Riprova.');
      }
    } else {
      setSelectedSimulation([]);
      setFieldValue('Simulazioni_scelte', []);
    }
  };

  useEffect(() => {
    const fetchAdditionalOptions = async () => {
      try {
        const response = await listAdditionalOptions();
        setAdditionalOptions(response);

        // Se values.ID_Simulazione è una stringa, la convertiamo in un array di numeri
        const ids = values.ID_Opzioni_aggiuntive ? values.ID_Opzioni_aggiuntive.split(",").map(Number) : [];
  
        // Trova i nomi delle simulazioni corrispondenti agli ID nel tuo set di dati
        const additionalOptionsNames = ids.map(
          (id: number) => response.find((additionalOption: any) => additionalOption.ID_Opzione_aggiuntiva === id)?.Nome
        );
        setSelectedAdditionalOptions(additionalOptionsNames);
      } catch (error) {
        console.error('Errore nel recupero delle opzioni aggiuntive:', error);
      }
    };
    fetchAdditionalOptions();
  }, []);
  
  const handleAdditionalOptionsChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setSelectedAdditionalOptions(selectedValues);
    setFieldValue('Opzioni_aggiuntive_scelte', selectedValues);
  };

const selectedSimulationOptions = selectedSimulation.map(nome => ({
  value: nome,
  label: nome,
}));

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Servizi</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/*Inizio piano d'abbonamento*/}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Piano d'abbonamento
        </label>
        
        <div className='row mb-2' data-kt-buttons='true'>
        <Select
          className='form-select-solid form-select-lg fw-bold'
          value={selectedPlan}
          options={subscriptionPlansList.map((pianoAbbonamento) => ({ value: pianoAbbonamento.ID_Piano_abbonamento, label: pianoAbbonamento.Nome }))}
          isSearchable={false}
          onChange={handlePlanChange}
          placeholder= "Seleziona un piano d'abbonamento"
        />
        </div>

        <div className='form-text'>
          
        </div>
      </div>
      {/*Fine piano d'abbonamento*/}

      {/*Inizio lunghezza abbonamento*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Lunghezza_abbonamento' className='form-label required'>
          Lunghezza abbonamento (mesi) (min. 3)
        </label>
        <Field
          type='number'
          id='Lunghezza_abbonamento'
          name='Lunghezza_abbonamento'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Lunghezza_abbonamento' />
          </div>  
      </div>
      {/*Fine lunghezza abbonamento*/}

      {/*Inizio simulazioni scelte*/}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Simulazioni scelte
        </label>

        <Select
          isMulti
          className='form-select-solid form-select-lg fw-bold'
          value={selectedSimulationOptions}
          placeholder="Scegli simulazioni"
          options={simulationsList.map((simulation) => ({ value: simulation.Nome, label: simulation.Nome }))}
          onChange={handleSimulationChange}
        />

        <div className='form-text'>
          
        </div>
      </div>
      {/*Fine simulazioni scelte*/}

      {/* Inizio opzioni aggiuntive */}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Opzioni aggiuntive
        </label>
        
        <Select
          isMulti
          className='form-select-solid form-select-lg fw-bold'
          value={selectedAdditionalOptions.map((nome) => ({ value: nome, label: nome }))}
          placeholder="Scegli opzioni aggiuntive"
          options={additionalOptions.map((option) => ({ value: option.Nome, label: option.Nome }))}
          onChange={handleAdditionalOptionsChange}
        />

      </div>
      {/* Fine opzioni aggiuntive */}

      {/*Inizio importo effettivo*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Importo_effettivo' className='form-label required'>
          Importo effettivo
        </label>
        <Field
          type='number'
          id='Importo_effettivo'
          name='Importo_effettivo'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Importo_effettivo' />
          </div>  
      </div>
      {/*Fine importo effettivo*/}

      {/*Inizio note aggiuntive*/}
      <div className='fv-row mb-10'>
        <label htmlFor='Note_aggiuntive' className='form-label'>
          Note aggiuntive
        </label>
        <Field
          as='textarea'
          id='Note_aggiuntive'
          name='Note_aggiuntive'
          className='form-control form-control-lg form-control-solid'
        />
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Note_aggiuntive' />
          </div>  
      </div>
      {/*Fine note aggiuntive*/}
    </div>
  )
}

export {Step3}
