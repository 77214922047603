/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {insertShipment} from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

const modalsRoot = document.getElementById('root-modals') || document.body

const InsertShipment = ({ show, handleClose, recordId, numGoggles, numGogglesAssigned }: Props) => {
  const [courier, setCourier] = useState<string | null>(null);
  const [cost, setCost] = useState<number | 0>(0);
  const [estimatedDepartureDate, setEstimatedDepartureDate] = useState<string | null>(null);
  const [estimatedArrivalDate, setEstimatedArrivalDate] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  

  const handleInsertShipment = async () => {
    try {
      await insertShipment(recordId, courier, cost, estimatedDepartureDate, estimatedArrivalDate);
      handleClose();
      setShowConfirmModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_insert_delivery_estimated"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Inserimento dati spedizione programmata</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-inlavorazione" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <label htmlFor="estimated-departure-date" className='form-label required'>Data partenza stimata  (se consegnato a mano, tutte le date di partenza e arrivo combacieranno)</label>
          <input
            type="date"
            id="estimated-departure-date"
            value={estimatedDepartureDate || ''}
            onChange={event => setEstimatedDepartureDate(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label htmlFor="arrival-date" className='form-label required'>Data arrivo stimato (se consegnato a mano, tutte le date di partenza e arrivo combacieranno)</label>
          <input
            type="date"
            id="estimated-arrival-date"
            value={estimatedArrivalDate || ''}
            onChange={event => setEstimatedArrivalDate(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label htmlFor="courier" className='form-label required'>Corriere (se consegnato a mano, scrivere "Di persona: " e il nome della persona)</label>
          <input
            type="text"
            id="courier"
            value={courier || ''}
            onChange={event => setCourier(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <label htmlFor="cost" className='form-label required'>Costo (se consegnato a mano, mettere 0)</label>
          <input
            type="number"
            id="cost"
            value={cost || 0}
            onChange={event => setCost(Number(event.target.value))}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <button className='btn btn-lg btn-light-inlavorazione me-3' onClick={handleInsertShipment}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Inserimento spedizione</h2>
        <div className="btn btn-sm btn-icon btn-active-color-inlavorazione" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>L'inserimento della spedizione è andata a buon fine!</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-inlavorazione me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { InsertShipment };